.login {
  background: linear-gradient(
    180deg,
    rgba(146, 220, 148, 1) 15%,
    rgba(40, 200, 220, 1) 80%
  );
  display: flex;
  height: 100vh;
  width: 100%;
}

.login__background {
  background-image: url("../../../assets/login_bg.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 0 0 auto;
  min-width: 58%;
}

.login__container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.login__container__form {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-width: 500px;
  width: 100%;
}

.login__container__form__logo {
  margin: 0 auto;
  margin-bottom: 48px;
}

.login__container__form__logo img {
  height: 98px;
  width: auto;
}

.login__container__form__label {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin-top: 6px;
}

.login__container__form__input {
  border: none;
  border-radius: 10px;
  color: #35393e;
  font-size: 18px;
  height: 30px;
  margin-top: 6px;
}

.login__container__form__button {
  background-color: transparent;
  border: solid 2px #fff;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  height: 35px;
  max-width: 500px;
  margin-top: 40px;
}

.login__container__form__label,
.login__container__form__input,
.login__container__form__button {
  margin-left: 12px;
  margin-right: 12px;
}

.login__container__form__error {
  color: tomato !important;
  max-width: 500px;
  margin-top: 30px;
}

@media (max-width: 600px) {
  .login {
    background: none;
  }

  .login__background {
    position: relative;
    width: 120%;
  }

  .login__container {
    background: linear-gradient(
      180deg,
      rgba(146, 220, 148, 1) 15%,
      rgba(40, 200, 220, 1) 80%
    );
    border-radius: 15px;
    min-height: 450px;
    left: 50%;
    margin-left: -45%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 90%;
  }

  .login__container span {
    padding-left: 0;
    text-align: center;
  }

  .login__container__form__logo {
    margin: 0 auto;
    margin-top: 48px;
    margin-bottom: 28px;
  }

  .login__container__form__logo img {
    height: 78px;
    width: auto;
  }

  .login__container__form__label {
    font-size: 16px;
  }
}
