.custom-dialog__multiple-action-buttons {
  flex: 1;
}

.roles-dialog__selection {
  margin: 0 22px 40px 22px;
}

.remove-dialog__warning,
.roles-dialog__warning {
  color: red !important;
}

.add-users-dialog {
  font-family: Arial, Helvetica, sans-serif;
}

.add-users-dialog__roles {
  display: flex;
  justify-content: center;
  margin-top: 22px;
}

.add-users-dialog__roles button {
  margin: 0 12px 0 12px;
}

button.add-users-dialog__button-PA,
button.add-users-dialog__button-PA-selected {
  border: solid 1px #fe7f81;
}

button.add-users-dialog__button-PM,
button.add-users-dialog__button-PM-selected {
  border: solid 1px #ffd200;
}

button.add-users-dialog__button-SI,
button.add-users-dialog__button-SI-selected {
  border: solid 1px #52e583;
}

button.add-users-dialog__button-PC,
button.add-users-dialog__button-PC-selected {
  border: solid 1px #26c6da;
}

button.add-users-dialog__button-PR,
button.add-users-dialog__button-PC-selected {
  border: solid 1px #bdbdbd;
}

button.add-users-dialog__button-selected {
  color: #fff;
}

button.add-users-dialog__button-PA-selected {
  background: #fe7f81;
}

button.add-users-dialog__button-PM-selected {
  background: #ffd200;
}

button.add-users-dialog__button-SI-selected {
  background: #52e583;
}

button.add-users-dialog__button-PC-selected {
  background: #26c6da;
}

button.add-users-dialog__button-PR-selected {
  background: #bdbdbd;
}

button.add-users-dialog__button-PA {
  color: #fe7f81;
}

button.add-users-dialog__button-PM {
  color: #ffd200;
}

button.add-users-dialog__button-SI {
  color: #52e583;
}

button.add-users-dialog__button-PC {
  color: #26c6da;
}

button.add-users-dialog__button-PR {
  color: #bdbdbd;
}

.add-users-dialog__projects {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
}

.add-users-dialog__projects__name {
  font-weight: 600;
}

.add-users-dialog__projects__team {
  margin: 12px 0 12px 0;
}

.add-user-dialog__projects__team__field {
  width: 100%;
}

.cost-groups-text {
  font-family: Arial, Helvetica, sans-serif;
}

.no-cost-groups {
  margin: 0 auto;
  width: fit-content;
}

.cost-groups-dialog {
  margin-bottom: 14px;
  min-width: 800px;
}

.cost-groups-dialog__actions {
  display: flex;
  margin-bottom: 6px;
}

.cost-groups-dialog__actions__project-name {
  align-self: center;
  display: flex;
  flex: 1;
  font-weight: bold;
}

.cost-groups-dialog__actions__button {
  flex: 0 0 180px;
}

.cost-groups-dialog__roles {
  background-color: #d9d9d9;
  display: flex;
  height: 28px;
  padding-left: 8px;
}

.cost-groups-dialog__roles__heading:first-child {
  flex: 1;
}

.cost-groups-dialog__roles__heading {
  align-self: center;
  display: flex;
  flex: 0 0 180px;
}

.cost-groups-dialog__roles-groups {
  display: flex;
  padding: 8px 0 0 8px;
}

.project-cost-groups {
  flex: 1;
  font-size: 14px;
}

.project-cost-groups__content {
  display: flex;
}

.project-cost-groups__content__name {
  flex: 1;
  margin-top: 8px;
}

.project-cost-groups__content__name__role {
  display: flex;
  flex: 0 0 180px;
}
