.cell {
  padding: 6px 8px 6px 8px !important;
}

.sticky-cell-head,
.sticky-cell-body {
  border-right: solid 2px gray;
  left: 0;
  position: sticky;
}

.sticky-cell-head {
  background-color: #fff !important;
  z-index: 20 !important;
}

.sticky-cell-body {
  z-index: 10 !important;
}

.cell-body__odd-bg {
  background-color: #eeeeee !important;
}

.cell-body__even-bg {
  background-color: #fff !important;
}
