.loading-wrapper {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sb-loading > div {
  background-color: #ffd200;
  display: inline-block;
  height: 18px;
  margin: 5px;
  width: 18px;
  animation: bouncedelay 1.4s infinite ease-in-out both;
}

.sb-loading .one {
  animation-delay: -0.32s;
}

.sb-loading .two {
  animation-delay: -0.16s;
  background-color: #50e682;
}

.sb-loading .three {
  background-color: #28c8dc;
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
