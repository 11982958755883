.matrix-search {
  display: flex;
  flex: 1;
  justify-content: center;
}

.matrix-search__filter {
  margin-right: 16px !important;
  width: 160px;
}

.matrix-search__input {
  width: 320px;
}
