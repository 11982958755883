body {
  margin: 0;
  padding: 0;
}

@media print {
  body {
    background-color: #fff;
  }
}
