.matrix-row {
  align-items: center;
  display: flex;
}

.matrix-row__project-cell {
  min-width: 124px !important;
}

.matrix-row__details {
  display: flex;
  flex-direction: column;
}

.matrix-row__details__user,
.matrix-row__details__role {
  font-size: 11px;
  font-weight: 500;
}

.matrix-row__details__email,
.matrix-row__details__team,
.matrix-row__details__projects-count {
  color: gray;
  font-size: 11px;
}

.matrix-row__details__cost-groups {
  color: #008cfe;
  cursor: pointer;
}

.matrix-row__checkbox {
  padding: 0 8px 0 0 !important;
}
