.members-matrix__table-head tr th,
.members-matrix__table-body tr td {
  border-bottom: solid 2px gray !important;
  border-right: solid 2px gray;
}

.members-matrix__table-head tr th {
  line-height: 15px;
  padding: 18px 0 8px 6px !important;
  font-size: 14px;
}

.members-matrix__table-head__content {
  display: flex;
}

.members-matrix__table-head__content__details {
  display: flex;
  flex-direction: column;
}

.members-matrix__table-head__content__details__user-count {
  color: gray;
  font-size: 11px;
  font-weight: normal;
}

.members-matrix__table-body tr td {
  padding: 4px 0 4px 6px !important;
}
