.header {
  background: #fff !important;
}

.header__logo {
  display: flex;
  align-items: center;
}

.header__logo__text {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-left: 14px;
}

.header__logo img {
  height: 26px;
  width: auto;
}

.button__roles,
.button__add,
.button__remove,
.button__csv {
  background-color: #9e9e9e !important;
}

.button__roles:hover,
.button__add:hover,
.button__remove:hover,
.button__csv:hover {
  background-color: #b1b1b1 !important;
}

.button__add,
.button__remove,
.button__csv,
.button__logout {
  margin-left: 12px !important;
}

.button__roles,
.button__add,
.button__remove,
.button__csv,
.button__logout {
  font-weight: 400 !important;
  font-size: 12px !important;
}

.button__roles:disabled,
.button__add:disabled,
.button__remove:disabled {
  color: #c8c8c8 !important;
}

.button__logout {
  background-color: #008cfe !important;
}

.button__logout:hover {
  background-color: #32a3fe !important;
}
