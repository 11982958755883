.dashboard {
  display: flex;
  flex-direction: column;
  height: 95vh;
  width: 100%;
}

.dashboard__header {
  display: flex;
  flex: 0 0 64px;
}

.dashboard__matrix {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: calc(100vh - 64px);
}
